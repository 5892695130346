@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0 !important;
  scroll-behavior: smooth;
}

@layer base {
  @font-face {
    font-family: "NeueMontrealMedium";
    src: url("./assets/fonts/NeueMontrealMedium.woff2") format("woff2"),
      url("./assets/fonts/NeueMontrealMedium.woff") format("woff"),
      url("./assets/fonts/NeueMontrealMedium.eot") format("eot"),
      url("./assets/fonts/NeueMontrealMedium.svg") format("svg"),
      url("./assets/fonts/NeueMontrealMedium.ttf") format("ttf");
  }
  @font-face {
    font-family: "SourceSansProBold";
    src: url("./assets/fonts/SourceSansProBold.woff2") format("woff2"),
      url("./assets/fonts/SourceSansProBold.woff") format("woff"),
      url("./assets/fonts/SourceSansProBold.eot") format("eot"),
      url("./assets/fonts/SourceSansProBold.svg") format("svg"),
      url("./assets/fonts/SourceSansProBold.ttf") format("ttf");
  }
  @font-face {
    font-family: "NeueMontrealRegular";
    src: url("./assets/fonts/NeueMontrealRegular.woff2") format("woff2"),
      url("./assets/fonts/NeueMontrealRegular.woff") format("woff"),
      url("./assets/fonts/SourceSansProBold.eot") format("eot"),
      url("./assets/fonts/NeueMontrealRegular.svg") format("svg"),
      url("./assets/fonts/NeueMontrealMedium.ttf") format("ttf");
  }
  @font-face {
    font-family: "VarelaRoundRegular";
    src: url("./assets/fonts/VarelaRoundRegular.woff2") format("woff2"),
      url("./assets/fonts/VarelaRoundRegular.woff") format("woff"),
      url("./assets/fonts/VarelaRoundRegular.eot") format("eot"),
      url("./assets/fonts/VarelaRoundRegular.svg") format("svg"),
      url("./assets/fonts/VarelaRoundRegular.ttf") format("ttf");
  }
  @font-face {
    font-family: "SaolDisplayRegular";
    src: url("./assets/fonts/SaolDisplayRegular.woff2") format("woff2"),
      url("./assets/fonts/SaolDisplayRegular.woff") format("woff"),
      url("./assets/fonts/SaolDisplayRegular.eot") format("eot"),
      url("./assets/fonts/SaolDisplayRegular.svg") format("svg"),
      url("./assets/fonts/SaolDisplayRegular.ttf") format("ttf");
  }
  @font-face {
    font-family: "PlusJakartaSansMedium";
    src: url("./assets/fonts/PlusJakartaSansMedium.eot");
    src: url("./assets/fonts/PlusJakartaSansMedium.eot")
        format("embedded-opentype"),
      url("./assets/fonts/PlusJakartaSansMedium.woff2") format("woff2"),
      url("./assets/fonts/PlusJakartaSansMedium.woff") format("woff"),
      url("./assets/fonts/PlusJakartaSansMedium.ttf") format("truetype"),
      url("./assets/fonts/PlusJakartaSansMedium.svg#PlusJakartaSansMedium")
        format("svg");
  }
  @font-face {
    font-family: "PlusJakartaSansSemiBold";
    src: url("./assets/fonts/PlusJakartaSansSemiBold.eot");
    src: url("./assets/fonts/PlusJakartaSansSemiBold.eot")
        format("embedded-opentype"),
      url("./assets/fonts/PlusJakartaSansSemiBold.woff2") format("woff2"),
      url("./assets/fonts/PlusJakartaSansSemiBold.woff") format("woff"),
      url("./assets/fonts/PlusJakartaSansSemiBold.ttf") format("truetype"),
      url("./assets/fonts/PlusJakartaSansSemiBold.svg#PlusJakartaSansSemiBold")
        format("svg");
  }
  @font-face {
    font-family: "SFPROTEXTSEMIBOLD";
    src: url("./assets/fonts/SFPROTEXTSEMIBOLD.eot");
    src: url("./assets/fonts/SFPROTEXTSEMIBOLD.eot") format("embedded-opentype"),
      url("./assets/fonts/SFPROTEXTSEMIBOLD.woff2") format("woff2"),
      url("./assets/fonts/SFPROTEXTSEMIBOLD.woff") format("woff"),
      url("./assets/fonts/SFPROTEXTSEMIBOLD.ttf") format("truetype"),
      url("./assets/fonts/SFPROTEXTSEMIBOLD.svg#SFPROTEXTSEMIBOLD")
        format("svg");
  }

  @font-face {
    font-family: "NohemiMediumBF6438cc5883899";
    src: url("./assets/fonts/NohemiMediumBF6438cc5883899.woff2") format("woff2"),
      url("./assets/fonts/NohemiMediumBF6438cc5883899.woff") format("woff"),
      url("./assets/fonts/NohemiMediumBF6438cc5883899.eot") format("eot"),
      url("./assets/fonts/NohemiMediumBF6438cc5883899.svg") format("svg"),
      url("./assets/fonts/NohemiMediumBF6438cc5883899.ttf") format("ttf");
  }
  @font-face {
    font-family: "InterRegular";
    src: url("./assets/fonts/InterRegular.eot");
    src: url("./assets/fonts/InterRegular.eot") format("embedded-opentype"),
      url("./assets/fonts/InterRegular.woff2") format("woff2"),
      url("./assets/fonts/InterRegular.woff") format("woff"),
      url("./assets/fonts/InterRegular.ttf") format("truetype"),
      url("./assets/fonts/InterRegular.svg#InterRegular") format("svg");
  }
  @font-face {
    font-family: "NohemiRegularBF6438cc4d0e493";
    src: url("./assets/fonts/NohemiRegularBF6438cc4d0e493.eot");
    src: url("./assets/fonts/NohemiRegularBF6438cc4d0e493.eot")
        format("embedded-opentype"),
      url("./assets/fonts/NohemiRegularBF6438cc4d0e493.woff2") format("woff2"),
      url("./assets/fonts/NohemiRegularBF6438cc4d0e493.woff") format("woff"),
      url("./assets/fonts/NohemiRegularBF6438cc4d0e493.ttf") format("truetype"),
      url("./assets/fonts/NohemiRegularBF6438cc4d0e493.svg#NohemiRegularBF6438cc4d0e493")
        format("svg");
  }

  /* for input or textarea css */
  form textarea::placeholder {
    font-size: 16px;
    font-family: "PlusJakartaSansMedium";
    line-height: 120%;
  }
  textarea {
    resize: none;
  }
  /* for input or textarea end */

  .text-gradient {
    background: url("./assets/images/text-bg.png");
    background-size: cover;
    color: #fff;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    padding: 0;
  }

  .banner-know-text span {
    font-family: "VarelaRoundRegular";
    font-size: 17.3px;
    line-height: 21px;
    color: #fff;
    position: absolute;
    left: 50%;
    transform-origin: 0 71px;
  }

  .height-90 {
    height: calc(100% - 90px);
  }

  /* layout height adjust */
  .height-layout {
    height: calc(100dvh - 121px);
    /* height: 100dvh; */
    overflow: hidden;
  }
  /* slick slider */
  .slick-slider {
    opacity: 0;
  }
  .slick-slider.slick-initialized {
    opacity: 1;
  }
  /* first slider */
  .first-slider .slick-list {
    padding: 0 90px 0 0px !important;
  }
  .first-slider .slick-slide > div {
    margin: 0 14px;
  }
  .first-slider .slick-slider {
    margin: 0 -14px;
  }
  /* third slider */
  .third-slider-main .slick-arrow.slick-prev {
    display: none !important;
  }
  .third-slider-main .slick-arrow.slick-next {
    right: 25px;
  }
  .third-slider-main .slick-arrow.slick-next::before {
    display: none;
  }
  .third-slider-main .slick-slide > div {
    margin: 0 10px;
    height: 100%;
  }
  .third-slider-main .slick-slider {
    margin: 0 0px 0 -10px;
  }
  .third-slider-main .slick-list {
    margin: 0 60px 0 0px;
  }
  .third-slider-main .slick-track {
    display: flex !important;
  }
  .third-slider-main .slick-slide {
    height: auto !important;
  }

  /* scrollbar css */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #010101;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    background-color: #000000;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
  }
  /* scrollbar css end */

  /* reward winning glowing effect css  */
  @keyframes glow {
    0%,
    100% {
      filter: drop-shadow(0 0 0px #fff);
      -webkit-filter: drop-shadow(0 0 0px #fff);
    }
    50% {
      filter: drop-shadow(0 0 100px #fff);
      -webkit-filter: drop-shadow(0 0 100px #fff);
    }
  }

  .glow-container {
    filter: drop-shadow(0 0 2px #fff);
    -webkit-filter: drop-shadow(0 0 2px #fff);
    width: auto;
    height: auto;
    animation: glow 5s infinite linear;
    -webkit-animation: glow 5s infinite linear;
  }

  /* glowing effect css end */

  /* card animation css */
  @keyframes slideOutRight {
    0% {
      transform: translateX(0);
      opacity: 1;
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
    }
    100% {
      transform: translateX(100%) rotate(35deg);
      opacity: 0;
      -webkit-transform: translateX(100%) rotate(35deg);
      -moz-transform: translateX(100%) rotate(35deg);
      -ms-transform: translateX(100%) rotate(35deg);
      -o-transform: translateX(100%) rotate(35deg);
    }
  }

  .animate-out {
    animation: slideOutRight 0.6s forwards;
    -webkit-animation: slideOutRight 0.6s forwards;
  }

  .arrow-up:hover svg {
    fill: yellow !important;
  }
}

#auth-sign-in{
  display: none !important;
}
.supabase-auth-ui_ui-divider{
  display: none!important;
}
.supabase-auth-ui_ui-button{
  border:none !important;
}

.slick-slide{
width: 150px !important;
}